<template>
  <div class="events" v-if="news">
    <div
        v-if="news && news.length"
        class="grid"
    >
      <event
          v-for="item in news"
          :key="item.id"
          :event="item"
          @click.native="goToNewsItem(item)"
      />
    </div>
    <div v-else class="events-plug">
      Ничего не найдено
    </div>
  </div>
</template>

<script>
import Event from "@/components/Parts/EventCard";

export default {
  name: "News",
  props: {
    limit: {
      type: Number,
      default: 0
    }
  },
  components: {
    Event,
  },
  data() {
    return {
      news: [],
    };
  },
  mounted() {
    this.getAllNews();
  },
  methods: {
    goToNewsItem(event) {
      this.$router.push({ path: `/news/${event.id}` });
      this.$store.dispatch("setSelectedNewsItem", event);
    },

    getAllNews() {
      api.getAllNews().then(res => {
        this.news = res.data;
      })
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";
.tags {
  position: absolute;
  bottom: 160px;
  width: 100%;
  background-color: #1e1e1e;
  border-top: 3px solid rgba(255, 255, 255, 0.16);
}

.events {
  .grid {
    margin: 0 32px;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .event {
      margin-bottom: 84px;
    }
  }

  .events-plug {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1052px;
    font-size: 56px;
  }
}
</style>
