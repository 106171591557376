<template>
  <content-wrapper>
    <news />
  </content-wrapper>
</template>

<script>
import News from "@/components/News";
import ContentWrapper from "@/components/Wrappers/ContentWrapper";

export default {
  components: {
    ContentWrapper,
    News,
  }
};
</script>
